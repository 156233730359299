@import 'normalize';

/**
 * 1. Prevent padding and border from affecting element width
 * https://goo.gl/pYtbK7
 */

html {
  box-sizing: border-box; /* 1 */
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.base-container {
  overflow: hidden;
  will-change: overflow;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}

.left-panel {
  width: 100%;
  background-color: #eee;
  padding: 1rem;
}

.right-panel {
  width: 100%;
  flex: 1 0;
}

.filters {
  margin: 2rem 0;
}

.about {
  padding: 1rem;
}
.about h1,
h3,
p {
  margin: 1rem 0;
}
.list {
  list-style-type: none;
}
.list li {
  list-style-type: none;
  margin: 1rem 0;
}

.ReactTable .rt-tbody {
  overflow: visible !important;
}

/* Enable momentum scrolling */
.ReactTable .rt-table {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

/* Small devices (landscape phones, 576px and up) */
/* @media (min-width: 576px) {

} */

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .left-panel {
    width: 320px;
    height: 100vh;
    overflow-y: auto;
  }
  .data-container {
    width: calc(100vw - 320px);
    overflow-x: hidden;
  }
  .about {
    height: 100vh;
    overflow-y: auto;
  }
  .ReactTable {
    height: 100vh;
  }
  .base-container {
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
}

/* Large devices (desktops, 992px and up) */
/* @media (min-width: 992px) {

} */

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .left-panel {
    width: 500px;
  }
  .data-container {
    width: calc(100vw - 500px);
    overflow-x: hidden;
  }
}

/* Large devices (desktops, 992px and up) */
@media (max-width: 1199px) {
  .filter-full-width {
    margin: 0 auto;
    width: 100%;
  }
}

.dialog-title {
  font-weight: bold !important;
}
.button-icon {
  height: 20px;
  margin-right: 8px;
  color: rgba(0, 0, 0, 0.54);
}
.list-item {
  justify-content: space-around;
}
.list-item-value {
  flex: initial;
}
.detail-popper {
  z-index: 1400;
}
.detail-popper-paper {
  max-width: 300px;
  padding: 10px;
}

.litemol-wrapper {
  height: 480px;
  position: relative;
  overflow-y: auto;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-center {
  height: 480px;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-wrap {
  flex-wrap: wrap;
}

@media (min-width: 1200px) {
  .xl\:flex-no-wrap {
    flex-wrap: nowrap;
  }
}
